import * as React from 'react'
import { Component } from 'react';
import { Link, RouteComponentProps } from "react-router-dom";
import { Form, Row, Col, Button, Input } from "antd-min";
import { AdminPathConfig as PathConfig } from "../../../config/pathconfig";
import { lazyInject, TYPES, CONSTS } from '../../../util/index';
import { withRouter, GLForm, GLFormComponentProps, GLUtil, FormItemsLayout, GLGlobal, InvitationType, RoleName, StateType, connect } from "gl-commonui";
import { IInvitationService, InvitationRequest, templateParams } from '../../../service/admin/acceptinvitation/index';
import { GSAdminLocale } from '../../../locales/localeid';
import { FormattedMessage } from "react-intl";

var Recaptcha = require('react-recaptcha');
type ValidateStatus = { hasFeedback?: boolean, status?: "success" | "warning" | "error" | "validating" }

export interface InvitationProps extends GLFormComponentProps {
    isSignin?: boolean
}
interface InvitationStates {
    valid?: ValidateStatus
    isClicked?: boolean
}

const mapStateToProps = (state: StateType) => {
    return {
        isSignin: state.oidc.loginInfo && state.oidc.loginInfo.loggedin,
    }
}

@withRouter
@GLForm.create()
@connect(({ oidc: { loginInfo } }: StateType) => ({
    isSignin: loginInfo && loginInfo.loggedin
}))
export class AcceptInvitationPage extends Component<InvitationProps & RouteComponentProps<any>, InvitationStates> {
    siteKey = "6LfmDlYnAAAAAJTgGfjkvnHEjs1o2ml33_VPGVt8"
    @lazyInject(TYPES.IInvitationService)
    service: IInvitationService
    invitationTypes = []
    constructor(props, context) {
        super(props, context);
        this.state = {};
        Object.keys(CONSTS.InvitationType).forEach(key => {
            if (key == InvitationType.regionAdmin.toString() ||
                key == InvitationType.trainingAdmin.toString() ||
                key == InvitationType.trainer.toString() ||
                key == InvitationType.globalHead.toString() ||
                key == InvitationType.systemAdmin.toString() ||
                key == InvitationType.contentAdmin.toString()) {
                this.invitationTypes.push(parseInt(key));
            }
        });
        // added new region coach invitation type
        this.invitationTypes.push(InvitationType.regionTrainer);
    }

    componentWillReceiveProps(nextProps: any, nextContext: any) {
        const { setFieldsValue } = this.props.form;
        nextProps.location !== this.props.location && setFieldsValue({ invitationCode: GLUtil.queryParse().code });
    }

    componentDidMount() {
        this.setState({ isClicked: false });
    }

    validateCode(rule, value, callback) {
        const params: InvitationRequest = {
            invitationType: this.invitationTypes,
            code: value.trim()
        }
        const status: ValidateStatus = {
            hasFeedback: true,
            status: 'validating'
        };
        this.setState({ valid: status })
        this.service.validate(params).then(data => {
            if (data.valid) {
                status.status = 'success'
                this.setState({ valid: status })
                callback();
            } else {
                status.status = 'error'
                this.setState({ valid: status })
                callback(GLGlobal.intl.formatMessage({ id: GSAdminLocale.InvitationValidErrMsg }));
            }
        })
    }

    callback() {
        //console.log("done");
    }

    recaptchaCallback(flag) {
        this.setState({ isClicked: flag });
    };

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const code = values.invitationCode.trim();
                this.props.history.push({ pathname: PathConfig.AcceptInvitationCode, search: GLUtil.queryStringify({ code: code }) });
            }
        });
    }
    render() {
        const code = GLUtil.queryParse().code;
        const { getFieldDecorator } = this.props.form;
        let isShow = this.props.isSignin ? "hide" : "show";
        let submitstyle = !this.props.isSignin && !this.state.isClicked ? "isforbid" : "";
        return <Row>
            <Row className='invitationsubject'>
                <FormattedMessage id={GSAdminLocale.InvitationSubject} ></FormattedMessage>
            </Row>
            <Row className='invitationtitle'>
                <FormattedMessage id={GSAdminLocale.InvitationCodeTitle} ></FormattedMessage>
            </Row>
            <GLForm className='acceptinvitation' form={this.props.form} onSubmit={this.handleSubmit.bind(this)}>
                <FormItemsLayout style={{ width: '304px', marginTop: "20px" }}>
                    <Form.Item
                        label={<FormattedMessage id={GSAdminLocale.InvitationValidCodes} />}
                        required={true}
                        {...this.state.valid}
                    >
                        {getFieldDecorator('invitationCode', {
                            initialValue: code,
                            validateFirst: true,
                            validateTrigger: [],
                            rules: [{
                                required: true,
                                whitespace: true,
                                message: GLGlobal.intl.formatMessage({ id: GSAdminLocale.ValidMsg })
                            }, {
                                validator: this.validateCode.bind(this)
                            }],
                        })(
                            <Input placeholder="" style={{ marginRight: 8 }} />
                        )}
                    </Form.Item>
                    <Form.Item className={isShow} style={{ marginBottom: "24px" }}>
                        {getFieldDecorator('g-recaptcha')(
                            <Recaptcha sitekey={this.siteKey}
                                render="explicit"
                                expiredCallback={() => this.recaptchaCallback(false)}
                                onloadCallback={() => this.callback()}
                                verifyCallback={() => this.recaptchaCallback(true)} />
                        )}
                    </Form.Item>
                    <Form.Item className='tableAction'>
                        <Button className={submitstyle + " invitationButton"} type="primary" htmlType="submit">
                            {GLGlobal.intl.formatMessage({ id: GSAdminLocale.InvitationNext })}
                        </Button>
                    </Form.Item>
                </FormItemsLayout>
            </GLForm>
        </Row>;
    }
}
